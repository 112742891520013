import { Component, OnInit, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu } from '@ng-bootstrap/ng-bootstrap';
import { ThrowStmt } from '@angular/compiler';
import { FormControl, Validators } from '@angular/forms';
const BASE_SCHOOL = 'Shamrock International School';
const OUR_BLUE = '#1976d2';
const OUR_RED = 'red';
const CHEST_MEASURE_LEGEND = 'Chest Measure of Garment';
const SHOULDER_MEASURE_LEGEND = 'Shoulder Measure of Garment';
const WAIST_MEASURE_LEGEND = 'Waist Measure of Garment';
const SHAMROCK_INTERNATIONAL_SCHOOL = BASE_SCHOOL;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'ReInvent Uniform Measurements App';
  JSON = {};
  sideLengthMeasurementTaken = false;
  alertMessage = '';
  showAlert = false;
  topMeasurementInRange: boolean;
  topMeasurementLegendColor = OUR_BLUE;
  bottomMeasurementInRange: boolean;
  bottomMeasurementLegendColor = OUR_BLUE;
  editing = true;
  viewingResults = false;
  bottomLength = new FormControl();
  genderSelector = new FormControl();
  groupSelector = new FormControl();
  chestm = new FormControl();
  waistm = new FormControl();
  posting = false;
  finalPayload = {response:[]}

  @ViewChild('myFormPost', null) myFormPost: ElementRef;

  // constructor (private http: HttpClient) {
  constructor () {

  // }
    // cdRef.detectChanges();
    this.JSON = JSON;
    window.scrollTo(0, 0);
  }

  schoolSelected = false;
  genderSelected = false;
  groupSelected = false;
  showMeasurementPanel = false;
  genderSelectedValue = '';
  groupSelectedValue = '';
  groupSelectedGradeValue = '';
  genderOption = '';
  groupOption = '';
  waistMeasured = false;
  chestMeasured = false;
  sideLengthDisplay = 0.0;
  showSizes = false;
  savedMeasurements = false;

  schools = [SHAMROCK_INTERNATIONAL_SCHOOL];
  selectedSchool = BASE_SCHOOL;
  genderOptions = [{ key: 'boys', displayText: 'Boy' }, { key: 'girls', displayText: 'Girl' }];
  // boysGroupOptions = [{key: 'nur2iii', grades: 'Nursery to III'}, {key: 'iv2v', grades: 'IV to V'}, {key: 'vi2x', grades: 'VI to X'}]
  // girlGroupOptions = [{key: 'nur2ii', grades: 'Nursery to II'}, {key: 'iii',  grades: 'III'}, {key: 'iv2x', grades: 'IV to X'}]
  boysGroupsGradeMap = [
  { key: 'nur2ukg', grade: 'Nursery' },
  { key: 'nur2ukg', grade: 'LKG' },
  { key: 'nur2ukg', grade: 'UKG' },
  { key: 'i2v', grade: 'I' },
  { key: 'i2v', grade: 'II' },
  { key: 'i2v', grade: 'III' },
  { key: 'i2v', grade: 'IV' },
  { key: 'i2v', grade: 'V' },
  { key: 'vi2viii', grade: 'VI' },
  { key: 'vi2viii', grade: 'VII' },
  { key: 'vi2viii', grade: 'VIII' }
  ];
  boysGroupOptions =
    [
    { key: 'nur2ukg', grades: 'Nursery to UKG', gradeNames: ['Nursery', 'LKG', 'UKG'] },
    { key: 'i2v', grades: 'I to V', gradeNames: ['I','II','III','IV','V'] },
    { key: 'vi2viii', grades: 'VI to VIII', gradeNames: ['VI','VII','VIII'] },
    ];
  girlsGroupsGradeMap = [
    { key: 'nur2ukg', grade: 'Nursery' },
    { key: 'nur2ukg', grade: 'LKG' },
    { key: 'nur2ukg', grade: 'UKG' },
    { key: 'i2v', grade: 'I' },
    { key: 'i2v', grade: 'II' },
    { key: 'i2v', grade: 'III' },
    { key: 'i2v', grade: 'IV' },
    { key: 'i2v', grade: 'V' },
    { key: 'vi2viii', grade: 'VI' },
    { key: 'vi2viii', grade: 'VII' },
    { key: 'vi2viii', grade: 'VIII' }  
  ];
  girlGroupOptions = [
    { key: 'nur2ukg', grades: 'Nursery to UKG', gradeNames: ['Nursery', 'LKG', 'UKG'] },
    { key: 'i2v', grades: 'I to V', gradeNames: ['I','II','III','IV','V'] },
    { key: 'vi2viii', grades: 'VI to VIII', gradeNames: ['VI','VII','VIII'] }
  ];
  groupOptions: any[] = ['Select Gender first'];
  groupsGradeMap: any[] = [];

  shamrock = {
    boys: {
      nur2ukg: {
        topMeasurements: {
          chestMinStops: [33.7, 35.6, 37.5, 39.4, 41.3, 43.2, 45.1, 48.1],
          chestMaxStops: [35.5, 37.4, 39.3, 41.2, 43.1, 45.0, 48.0, 51.2],
          chestMeasurementTuples: [],
          topLengths: [46.5, 49.0, 51.5, 54.0, 57.5, 60.5, 63.5, 71.0],
          mainTop: { name: 'Shirt - Akash', sizes: ['4','5','6','8','10','12','14','16'] },
          otherProducts: [
          ]
        },
        bottomMeasurements: {
          waistMinStops: [22.9, 25.4, 28.1, 30.5, 33.1, 35.8],
          waistRelaxStops: [25.3, 28.0, 30.4, 33.0, 35.7, 38.4],
          waistMeasurementTuples: [],
          sideLengths: [[31.0], [33.0], [35.0], [37.0], [39.0], [40.5]],
          mainBottom: {
            name: 'Shorts - Khaki',
            sizes: [['18'], ['20'], ['22'], ['24'], ['26'], ['28']]
          },
          otherProducts: [
            { name: 'Belt', sizes: ['S', 'S', 'S', 'M', 'M', 'M'] },
          ]
        },
        accessories: [
          { grade: 'Nursery', name: 'Socks', sizes: ['3'] },
          { grade: 'LKG', name: 'Socks', sizes: ['3'] },
          { grade: 'UKG', name: 'Socks', sizes: ['3'] }
        ],
        images: {
          topMeasurementsImg: '../assets/images/dashboard/measurements/cha_pu_boys_shirt.jpeg',
          bottomMeasurementsImg: '../assets/images/dashboard/measurements/reinvent-uniform-bottom-measure.png'
        },
        legend: {
          topMeasurementsLegend: CHEST_MEASURE_LEGEND,
          bottomMeasurementsLegend: WAIST_MEASURE_LEGEND
        }
      },
      i2v: {
        topMeasurements: {
          chestMinStops: [33.7, 35.6, 37.5, 39.4, 41.3, 43.2, 45.1, 48.1, 51.3, 54.5, 57.7],
          chestMaxStops: [35.5, 37.4, 39.3, 41.2, 43.1, 45.0, 48.0, 51.2, 54.4, 57.6, 60.8],
          chestMeasurementTuples: [],
          topLengths: [46.5, 49.0, 51.5, 54.0, 57.5, 60.5, 63.5, 71.0, 73.5, 73.5, 73.5],
          mainTop: { name: 'Shirt - Akash', sizes: ['4', '5', '6', '8', '10', '12', '14', '16', '18', '20', '22'] },
          otherProducts: [
            { name: 'Sports Tee', sizes: [ '6', '8', '8', '10', '10', '12', '14', '16', '16', '18', '20' ] },
            { name: 'Blazer', sizes: ['32', '34', '36', '38', '40', '42', '44', '46', '48', '50', '52/54'] } ]
        },
        bottomMeasurements: {
          waistMinStops: [22.9, 25.4, 28.1, 30.5, 33.1, 35.8, 38.5, 41.2, 43.9, 46.6],
          waistRelaxStops: [25.3, 28.0, 30.4, 33.0, 35.7, 38.4, 41.1, 43.8, 46.5, 49.2],
          waistMeasurementTuples: [],
          sideLengths: [ [31.0], [33.0], [35.0], [37.0], [39.0], [40.5], [42.0], [42.0], [42.0], [42.0]],
          mainBottom: {
            name: 'Shorts - Khaki',
            sizes: [['18'], ['20'], ['22'], ['24'], ['26'], ['28'], ['30'], ['32'], ['34'], ['36']]
          },
          otherProducts: [
            { name: 'Sports Shorts', sizes: ['3XS', '3XS', '2XS', 'XS', 'S', 'M', 'M', 'L', 'L', 'XL'] },
            { name: 'Belt', sizes: ['S', 'S', 'S', 'M', 'M', 'M', 'M', 'M', 'XL', 'XL'] },
          ]
        },
        accessories: [
          { grade: 'I', name: 'Socks', sizes: ['3'] },
          { grade: 'II', name: 'Socks', sizes: ['3'] },
          { grade: 'III', name: 'Socks', sizes: ['4'] },
          { grade: 'IV', name: 'Socks', sizes: ['4'] },
          { grade: 'V', name: 'Socks', sizes: ['5'] }
        ],
        images: {
          topMeasurementsImg: '../assets/images/dashboard/measurements/cha_pu_boys_shirt.jpeg',
          bottomMeasurementsImg: '../assets/images/dashboard/measurements/reinvent-uniform-bottom-measure.png'
        },
        legend: {
          topMeasurementsLegend: CHEST_MEASURE_LEGEND,
          bottomMeasurementsLegend: WAIST_MEASURE_LEGEND
        }
      },
      vi2viii: {
        topMeasurements: {
          chestMinStops: [39.4, 41.3, 43.2, 45.1, 48.1, 51.3, 54.5, 57.7, 60.9],
          chestMaxStops: [41.2, 43.1, 45, 48, 51.2, 54.4, 57.6, 60.8, 64.0],
          chestMeasurementTuples: [],
          topLengths: [56.0, 59.5, 63.0, 67.0, 71.0, 73.5, 73.5, 73.5, 73.5],
          mainTop: { name: 'Shirt - Akash', sizes: ['8', '10', '12', '14', '16', '18', '20', '22', '24'] },
          otherProducts: [
            { name: 'Sports Tee', sizes: [ '10', '10', '12', '14', '16', '16', '18', '20', '20' ] },
            { name: 'Blazer', sizes: ['38', '40', '42', '44', '46', '48', '50', '52/54', '56' ] }
          ]
        },
        bottomMeasurements: {
          waistMinStops: [25.9, 28.1, 30.5, 33.1, 35.8, 38.5, 41.2, 43.9, 46.6, 49.3, 51.8],
          waistRelaxStops: [28.2, 30.4, 33, 35.7, 38.4, 41.1, 43.8, 46.5, 49.2, 51.7, 54.4],
          waistMeasurementTuples: [],
          sideLengths: [ [82], [87.0], [91.5], [96.0], [100.0], [105.0], [105.5], [106.0], [106.5], [107.5], [108.0]],
          mainBottom: {
            name: 'Trousers - Khaki',
            sizes: [['20'], ['22'], ['24'], ['26'], ['28'], ['30'], ['32'], ['34'], ['36'], ['38'], ['40']]
          },
          otherProducts: [
            { name: 'Track', sizes: ['19', '19', '22', '25', '28', '28', '31', '31', '34', '34', '37'] },
            { name: 'Belt', sizes: ['S', 'S', 'S', 'M', 'M', 'M', 'M', 'XL', 'XL', 'XL', 'XL'] },
          ]
        },
        accessories: [
          { grade: 'VI', name: 'Socks', sizes: ['5'] },
          { grade: 'VII', name: 'Socks', sizes: ['6'] },
          { grade: 'VIII', name: 'Socks', sizes: ['FREE'] }
        ],
        images: {
          topMeasurementsImg: '../assets/images/dashboard/measurements/cha_pu_boys_shirt.jpeg',
          bottomMeasurementsImg: '../assets/images/dashboard/measurements/Trouser.png'
        },
        legend: {
          topMeasurementsLegend: CHEST_MEASURE_LEGEND,
          bottomMeasurementsLegend: WAIST_MEASURE_LEGEND
        }
      },
    },
    girls: {
      nur2ukg: {
        topMeasurements: {
          chestMinStops: [33.8, 35.7, 37.5, 39.4, 41.3, 43.2, 45.1, 47.6],
          chestMaxStops: [35.6, 37.4, 39.3, 41.2, 43.1, 45.0, 47.5, 50.1],
          chestMeasurementTuples: [],
          topLengths: [46.5, 50.5, 53.0, 54.5, 57.5, 60.5, 64.0, 67.0],
          mainTop: {
            name: 'Tie Shirt - Akash',
            sizes: ['4', '5', '6', '8', '10','12', '14', '16'] },
            otherProducts: [
              // { name: 'Sports Tee', sizes: ['6', '8', '8', '10', '10', '12', '14', '16', '16', '18', '20', '22', '22'] },
            ]
        },
        bottomMeasurements: {
          waistMinStops: [22.9, 25.4, 28.1, 30.5, 33.1, 35.8, 35.8, 41.2],
          waistRelaxStops: [25.3, 28.0, 30.4, 33.0, 35.7, 38.4, 41.1, 43.8],
          waistMeasurementTuples: [],
          sideLengths: [[27.0], [28.5], [30.0], [31.0], [33.0], [35.0], [37.0], [39.0]],
          mainBottom: {
            name: 'Skorts - Khaki',
            sizes: [['18'], ['20'], ['22'], ['24'], ['26'], ['28'], ['30'], ['32']]
          },
          otherProducts: [
            // { name: 'Belt', sizes: ['S', 'S', 'S', 'M', 'M', 'M'] },
          ]
        },
        accessories: [
          { grade: 'Nursery', name: 'Socks', sizes: ['3'] },
          { grade: 'LKG', name: 'Socks', sizes: ['3'] },
          { grade: 'UKG', name: 'Socks', sizes: ['3'] }
        ],
        images: {
          topMeasurementsImg: '../assets/images/dashboard/measurements/girls_shirt.jpeg',
          bottomMeasurementsImg: '../assets/images/dashboard/measurements/Skorts.jpeg'
        },
        legend: {
          topMeasurementsLegend: CHEST_MEASURE_LEGEND,
          bottomMeasurementsLegend: WAIST_MEASURE_LEGEND
        }
      },
      i2v: {
        topMeasurements: {
          chestMinStops: [33.8, 35.7, 37.5, 39.4, 41.3, 43.2, 45.1, 47.6, 50.2, 52.7, 55.3, 57.8],
          chestMaxStops: [35.6, 37.4, 39.3, 41.2, 43.1, 45.0, 47.5, 50.1, 52.6, 55.2, 57.7, 60.2],
          chestMeasurementTuples: [],
          topLengths: [46.5, 50.5, 53.0, 54.5, 57.5, 60.5, 64.0, 67.0, 68.5, 68.5, 68.5, 68.5],
          mainTop: { name: 'Tie Shirt - Akash', sizes: ['4', '5', '6', '8', '10', '12', '14', '16', '18', '20', '22', '24'] },
          otherProducts: [
            { name: 'Sports Tee', sizes: ['6', '8', '8', '10', '10', '12', '14', '16', '16', '18', '20', '20'] },
            { name: 'Blazer', sizes: [ '32', '34', '36', '38', '40', '42', '44', '46', '48', '50', '52/54', '56' ] }
          ]
        },
        bottomMeasurements: {
          waistMinStops: [26.4, 29.1, 31.6, 34.1, 36.6, 39.2, 41.8, 44.3, 46.8],
          waistRelaxStops: [ 29.0, 31.5, 34.0, 36.5, 39.1, 41.7, 44.2, 46.7, 49.3 ],
          waistMeasurementTuples: [],
          // waistMeasurementTuples: this.cha.girls.nur2ii.bottomMeasurements.waistMinStops.map((item, index) => {
          //   return [item, this.cha.girls.nur2ii.bottomMeasurements.waistRelaxStops[index]]
          // }),
          sideLengths: [ [42], [45.5], [48], [50.5], [53], [55.5], [57], [58.5], [58.5] ],
          mainBottom: {
            name: 'Skirt - Khaki',
            sizes: [['4'], ['5'], ['6'], ['7'], ['8'], ['9'], ['10'], ['11'], ['12']]
          },
          otherProducts: [
            { name: 'Sports Shorts', sizes: ['2XS', 'XS', 'S', '2M', 'M', 'L', 'L', 'XL', 'XL'] },
            { name: 'Cycling Shorts', sizes: ['S', 'M', 'M', 'L', 'L', 'XL', 'XL', '2XL', '2XL'] },
            { name: 'Belt', sizes: ['S', 'S', 'M', 'M', 'M', 'M', 'XL', 'XL', 'XL'] }
          ]
        },  
        accessories: [
          { grade: 'I', name: 'Socks', sizes: ['3'] },
          { grade: 'II', name: 'Socks', sizes: ['3'] },
          { grade: 'III', name: 'Socks', sizes: ['4'] },
          { grade: 'IV', name: 'Socks', sizes: ['4'] },
          { grade: 'V', name: 'Socks', sizes: ['5'] }
        ],
        images: {
          topMeasurementsImg: '../assets/images/dashboard/measurements/girls_shirt.jpeg',
          // topMeasurementsImg: '../assets/images/dashboard/measurements/reinvent-uniform-top-measure.png',
          bottomMeasurementsImg: '../assets/images/dashboard/measurements/Pleated_Skirt.png'
        },
        legend: {
          topMeasurementsLegend: CHEST_MEASURE_LEGEND,
          bottomMeasurementsLegend: WAIST_MEASURE_LEGEND
        }        
      },
      vi2viii: {
        topMeasurements: {
          chestMinStops: [33.8, 35.7, 37.5, 39.4, 41.3, 43.2, 45.1, 47.6, 50.2, 52.7, 55.3, 57.8],
          chestMaxStops: [35.6, 37.4, 39.3, 41.2, 43.1, 45, 47.5, 50.1, 52.6, 55.2, 57.7, 60.2],
          chestMeasurementTuples: [],
          topLengths: [46.5, 50.5, 53.0, 54.5, 57.5, 60.5, 64.0, 67.0, 68.5, 68.5, 68.5, 68.5],
          mainTop: { name: 'Tie Shirt - Akash', sizes: ['4', '5', '6', '8', '10', '12', '14', '16', '18', '20', '22', '24'] },
          otherProducts: [
            { name: 'Sports Tee', sizes: ['6', '8', '8', '10', '10', '12', '14', '16', '16', '18', '20', '20'] },
            { name: 'Blazer', sizes: [ '32', '34', '36', '38', '40', '42', '44', '46', '48', '50', '52/54', '56' ] },
          ]
        },
        bottomMeasurements: {
          waistMinStops: [26.4, 29.1, 31.6, 34.1, 36.6, 39.2, 41.8, 44.3, 46.8, 49.4],
          waistRelaxStops: [29.0, 31.5, 34.0, 36.5, 39.1, 41.7, 44.2, 46.7, 49.3, 51.9],
          waistMeasurementTuples: [],
          sideLengths: [[42], [45.5], [48], [50.5], [53], [55.5], [57], [58.5], [58.5], [58.5]],
          mainBottom: {
            name: 'Skirt - Khaki',
            sizes: [['4'], ['5'], ['6'], ['7'], ['8'], ['9'], ['10'], ['11'], ['12'], ['14']]
          },
          otherProducts: [
            { name: 'Track', sizes: ['19', '22', '25', '28', '28', '31', '31', '34', '34', '37'] },
            { name: 'Cycling Shorts', sizes: ['S', 'M', 'M', 'L', 'L', 'XL', 'XL', '2XL', '2XL', '3XL'] },
            { name: 'Belt', sizes: ['S', 'S', 'M', 'M', 'M', 'M', 'XL', 'XL', 'XL', 'XL'] }

          ]
        },
        accessories: [
          { grade: 'VI', name: 'Socks', sizes: ['5'] },
          { grade: 'VII', name: 'Socks', sizes: ['6'] },
          { grade: 'VIII', name: 'Socks', sizes: ['Free'] }
        ],
        images: {
          topMeasurementsImg: '../assets/images/dashboard/measurements/girls_shirt.jpeg',
          // topMeasurementsImg: '../assets/images/dashboard/measurements/Frock.jpg',
          bottomMeasurementsImg: '../assets/images/dashboard/measurements/Pleated_Skirt.png'
        },
        legend: {
          topMeasurementsLegend: SHOULDER_MEASURE_LEGEND,
          bottomMeasurementsLegend: WAIST_MEASURE_LEGEND
        }
      }
    }
};
  suggestedTopLengths = [81.0];
  suggestedBottomLengths = [];
  waistMeasurement = 0;
  sideLengthBottom = 0;
  chestMeasurement = 0;
  topMeasurementImage = '';
  bottomMeasurementImage = '';
  topMeasurementLegend = '';
  bottomMeasurementLegend = '';
  sideLengthTop = 0;
  finalSizeMaps = [];
  schoolNGrades = {};
  viewModel = [];
  sizeObject: any = {};

  sizes: [
    { name: 'Product 1', size: 'Size A' },
    { name: 'Product 2', size: 'Size B' },
    { name: 'Product 3', size: 'Size C' },
  ];



  matchedWaistMeasurementIndex = 1;
  matchedChestMeasurementIndex = 1;

  // suggestedSizeArray = [];
  suggestedSizeKeys = [];
  suggestedSizeObj = {};

  ngOnInit() {
    if (this.selectedSchool === BASE_SCHOOL) {
      this.schoolNGrades = this.shamrock.girls.nur2ukg;
      this.sizeMeasurementHelpModal({ temp: 'value' });
    }
    this.setupMeasurementRanges();
  }

  setupMeasurementRanges = () => {
    this.shamrock.boys.nur2ukg.topMeasurements.chestMeasurementTuples = this.shamrock.boys.nur2ukg.topMeasurements.chestMinStops.map((item, index) =>
      [item, this.shamrock.boys.nur2ukg.topMeasurements.chestMaxStops[index]]
    );
    this.shamrock.boys.i2v.topMeasurements.chestMeasurementTuples = this.shamrock.boys.i2v.topMeasurements.chestMinStops.map((item, index) =>
      [item, this.shamrock.boys.i2v.topMeasurements.chestMaxStops[index]]
    );
    this.shamrock.boys.vi2viii.topMeasurements.chestMeasurementTuples = this.shamrock.boys.vi2viii.topMeasurements.chestMinStops.map((item, index) =>
      [item, this.shamrock.boys.vi2viii.topMeasurements.chestMaxStops[index]]
    );
    this.shamrock.boys.nur2ukg.bottomMeasurements.waistMeasurementTuples = this.shamrock.boys.nur2ukg.bottomMeasurements.waistMinStops.map((item, index) =>
      [item, this.shamrock.boys.nur2ukg.bottomMeasurements.waistRelaxStops[index]]
    );
    this.shamrock.boys.i2v.bottomMeasurements.waistMeasurementTuples = this.shamrock.boys.i2v.bottomMeasurements.waistMinStops.map((item, index) =>
      [item, this.shamrock.boys.i2v.bottomMeasurements.waistRelaxStops[index]]
    );
    this.shamrock.boys.vi2viii.bottomMeasurements.waistMeasurementTuples = this.shamrock.boys.vi2viii.bottomMeasurements.waistMinStops.map((item, index) =>
      [item, this.shamrock.boys.vi2viii.bottomMeasurements.waistRelaxStops[index]]
    );
    this.shamrock.girls.nur2ukg.topMeasurements.chestMeasurementTuples = this.shamrock.girls.nur2ukg.topMeasurements.chestMinStops.map((item, index) =>
      [item, this.shamrock.girls.nur2ukg.topMeasurements.chestMaxStops[index]]
    );
    this.shamrock.girls.i2v.topMeasurements.chestMeasurementTuples = this.shamrock.girls.i2v.topMeasurements.chestMinStops.map((item, index) =>
      [item, this.shamrock.girls.i2v.topMeasurements.chestMaxStops[index]]
    );
    this.shamrock.girls.vi2viii.topMeasurements.chestMeasurementTuples = this.shamrock.girls.vi2viii.topMeasurements.chestMinStops.map((item, index) =>
      [item, this.shamrock.girls.vi2viii.topMeasurements.chestMaxStops[index]]
    );
    this.shamrock.girls.nur2ukg.bottomMeasurements.waistMeasurementTuples = this.shamrock.girls.nur2ukg.bottomMeasurements.waistMinStops.map((item, index) =>
      [item, this.shamrock.girls.nur2ukg.bottomMeasurements.waistRelaxStops[index]]
    );
    this.shamrock.girls.i2v.bottomMeasurements.waistMeasurementTuples = this.shamrock.girls.i2v.bottomMeasurements.waistMinStops.map((item, index) =>
      [item, this.shamrock.girls.i2v.bottomMeasurements.waistRelaxStops[index]]
    );
    this.shamrock.girls.vi2viii.bottomMeasurements.waistMeasurementTuples = this.shamrock.girls.vi2viii.bottomMeasurements.waistMinStops.map((item, index) =>
      [item, this.shamrock.girls.vi2viii.bottomMeasurements.waistRelaxStops[index]]
    );
  }
  //
  sizeSelectedGroup = (productId, variantId) => {
    // this.sizeObject[productId] = variantId;
    let temp;
    if (this.sizeObject[productId] !== undefined) {
      temp = this.sizeObject[productId];
      // tslint:disable-next-line:no-string-literal
      temp['vid'] = variantId;
      this.sizeObject[productId] = temp;
    } else {
      temp = { vid: variantId };
      this.sizeObject[productId] = temp;
    }

  }
  // Section - Size measurement functions
  saveMeasurements = () => {
    console.log('Running SaveMeasurements');
    if (!this.chestMeasured) {
      this.alertMessage = 'You must specify "' + this.topMeasurementLegend + '".';
      this.showAlert = true;
      alert(this.alertMessage);
      console.log('No chest measurement - returning');
      return;
    } else if (!this.waistMeasured) {
      this.alertMessage = 'You must specify "' + this.bottomMeasurementLegend + '".';
      this.showAlert = true;
      alert(this.alertMessage);
      return;
    } else if (!this.sideLengthMeasurementTaken) {
      this.alertMessage = 'You must Select Length for "Main - Bottom" from the drop-down values';
      this.showAlert = true;
      console.log('No side length measurement - returning');
      return;
    } else if (!this.topMeasurementInRange) {
      this.alertMessage = this.chestMeasurement + ' is outside the specified range.';
      this.showAlert = true;
      alert(this.alertMessage);
      console.log('No top measurement - returning');
      return;
    } else if (!this.bottomMeasurementInRange) {
      this.alertMessage = this.waistMeasurement + ' is outside the specified range.';
      this.showAlert = true;
      alert(this.alertMessage);
      return;
    }
    console.log('Checked for alerts');
    this.alertMessage = '';
    this.showAlert = false;

    interface IProductSizes { name: any; sizes: any; }
    // tslint:disable-next-line:prefer-const
    // tslint:disable-next-line:no-angle-bracket-type-assertion
    const bottomMeasurementsOtherProducts: any = this.schoolNGrades['bottomMeasurements']['otherProducts'].map(item => <IProductSizes>{
      name: item.name,
      sizes: item.sizes
    });

    // tslint:disable-next-line:no-string-literal
    // tslint:disable-next-line:no-angle-bracket-type-assertion
    // tslint:disable-next-line:no-string-literal
    // tslint:disable-next-line:no-angle-bracket-type-assertion
    // tslint:disable-next-line:no-string-literal
    const topMeasurementsOtherProducts: any = this.schoolNGrades['topMeasurements']['otherProducts']
      // tslint:disable-next-line:no-angle-bracket-type-assertion
      .map(element => <IProductSizes> {
        name: element.name,
        sizes: element.sizes
      });

      
    // tslint:disable-next-line:no-string-literal
    const mainBottomSizesArray = this.schoolNGrades['bottomMeasurements']['mainBottom']['sizes'][this.matchedWaistMeasurementIndex];
    // const mainBottomMeasurement = mainBottomSizesArray.length > 1 ? '28LXXX' : mainBottomSizesArray[0];
    this.finalSizeMaps = [
      // tslint:disable-next-line:no-string-literal
      {
        name: [this.schoolNGrades['topMeasurements']['mainTop']['name']],
        // tslint:disable-next-line:no-string-literal
        size: this.schoolNGrades['topMeasurements']['mainTop']['sizes'][this.matchedChestMeasurementIndex]
      },
      //  {name: [this.schoolNGrades['bottomMeasurements']['mainBottom']['name']], size: 'Size ' + mainBottomMeasurement }
      // tslint:disable-next-line:no-string-literal
      {
        name: [this.schoolNGrades['bottomMeasurements']['mainBottom']['name']],
        // tslint:disable-next-line:no-string-literal
        size: this.schoolNGrades['bottomMeasurements']['mainBottom']['sizes'][this.matchedWaistMeasurementIndex]
      }
      // {name: [this.schoolNGrades['accessories'][0]['name']],  size: 'Size ' + this.schoolNGrades['accessories'][0]['sizes'][0] }
    ];
    bottomMeasurementsOtherProducts.forEach((element, index) => {

      element.sizes.some((item, eIndex) => {
        if (eIndex === this.matchedWaistMeasurementIndex) {
          this.finalSizeMaps.push({ name: [element.name], size: element.sizes[eIndex] })
        }
      });
    });
    topMeasurementsOtherProducts.forEach((element, index) => {

      element.sizes.some((item, eIndex) => {
        if (eIndex === this.matchedChestMeasurementIndex) {
          this.finalSizeMaps.push({ name: [element.name], size: element.sizes[eIndex] })
        }
      });
    });
    // tslint:disable-next-line:no-string-literal
    this.schoolNGrades['accessories'].forEach((acc, indx) => {
      let thisAccessory = {};
      if (acc.grade === this.groupSelectedGradeValue) {
        thisAccessory = {
          name: acc.name,
          size: 'Size ' + acc.sizes[0]
        };
        this.finalSizeMaps.push(thisAccessory)
      }
    });

    this.savedMeasurements = true;
    this.editing = false;
    this.viewingResults = true;

    // tslint:disable-next-line:no-string-literal
    console.log([this.schoolNGrades['topMeasurements']['mainTop']['name']]);
    this.finalSizeMaps = [
      // tslint:disable-next-line:no-string-literal
      {
        // tslint:disable-next-line:no-string-literal
        name: [this.schoolNGrades['topMeasurements']['mainTop']['name']],
        // tslint:disable-next-line:no-string-literal
        size: this.schoolNGrades['topMeasurements']['mainTop']['sizes'][this.matchedChestMeasurementIndex]
      },
      //  {name: [this.schoolNGrades['bottomMeasurements']['mainBottom']['name']], size: 'Size ' + mainBottomMeasurement }
      // tslint:disable-next-line:no-string-literal
      {
        name: [this.schoolNGrades['bottomMeasurements']['mainBottom']['name']],
        // tslint:disable-next-line:no-string-literal
        size: this.schoolNGrades['bottomMeasurements']['mainBottom']['sizes'][this.matchedWaistMeasurementIndex]
      }
      // {name: [this.schoolNGrades['accessories'][0]['name']],  size: 'Size ' + this.schoolNGrades['accessories'][0]['sizes'][0] }

    ];
    bottomMeasurementsOtherProducts.forEach((element, index) => {

      element.sizes.some((item, eIndex) => {
        if (eIndex === this.matchedWaistMeasurementIndex) {
          this.finalSizeMaps.push({ name: [element.name], size: element.sizes[eIndex] })
        }
      });
    });
    topMeasurementsOtherProducts.forEach((element, index) => {

      element.sizes.some((item, eIndex) => {
        if (eIndex === this.matchedChestMeasurementIndex) {
          this.finalSizeMaps.push({ name: [element.name], size: element.sizes[eIndex] })
        }
      });
    });
    // tslint:disable-next-line:no-string-literal
    this.schoolNGrades['accessories'].forEach((acc, indx) => {
      let thisAccessory = {};
      if (acc.grade === this.groupSelectedGradeValue) {
        thisAccessory = {
          name: acc.name,
          size: 'Size ' + acc.sizes[0]
        };
        this.finalSizeMaps.push(thisAccessory)
      }
    });

    this.savedMeasurements = true;
    console.log(this.savedMeasurements);
    this.editing = false;
    this.viewingResults = true;
    console.log('Calling Check Show Sizes from saveMeasurements');
    this.checkShowSizes();
  }

  resetMeasurements = () => {
    this.bottomLength = new FormControl();
    this.genderSelector = new FormControl();
    this.groupSelector = new FormControl();
    this.chestm = new FormControl();
    this.waistm = new FormControl();
    this.chestMeasured = false;
    this.waistMeasured = false;
    this.topMeasurementInRange = false;
    this.bottomMeasurementInRange = false;
  
    // this.bottomLength.reset();
    // this.genderSelector.reset();
    // this.groupSelector.reset();
    // this.chestm.reset();
    // this.waistm.reset();
    this.schoolSelected = false;
    this.sideLengthMeasurementTaken = false;
    this.showSizes = false;
    this.savedMeasurements = false;
    this.viewingResults = false;
    this.editing = true;
    this.showMeasurementPanel = false;
  }
  saveChestMeasurement(e) {
    e = (e) ? e : window.event;
    // tslint:disable-next-line:prefer-const
    let charCode = (e.which) ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  saveWaistMeasurement(e) {
    e = (e) ? e : window.event;
    // tslint:disable-next-line:prefer-const
    let charCode = (e.which) ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  setSideLengthValue(productId, quantity) {
    // this.sizeObject[productId] = variantId;
    let temp;
    if (this.sizeObject[productId] !== undefined) {
      temp = this.sizeObject[productId];
      // tslint:disable-next-line:no-string-literal
      temp['qty'] = quantity;
      this.sizeObject[productId] = temp;
    } else {
      temp = { qty: quantity };
      this.sizeObject[productId] = temp;
    }
  }
  sizeMeasurementHelpModal(studentSuggestedSize) {
    // this.suggestedSizeKeys = Object.keys(studentSuggestedSize) || [];
  }
  sizeMeasurementAYSModal() {
    this.suggestedSizeKeys = [];
    // if(typeof studentAskSure === 'object') {
    //   this.suggestedSizeKeys = Object.keys(studentAskSure)
    // }
  }
  // Chest measurement and top length calculation
  saveChestMeasurementData = (chestMeasurement, eventName) => {
    if ( chestMeasurement && chestMeasurement.length <= 0 ) {
      return;
    }
    if ( eventName && eventName === 'focusout' ) {
      // tslint:disable-next-line:no-string-literal
      if (chestMeasurement < this.schoolNGrades['topMeasurements']['chestMinStops'][0] ||
      chestMeasurement >
      // tslint:disable-next-line:no-string-literal
      this.schoolNGrades['topMeasurements']['chestMaxStops'][ this.schoolNGrades['topMeasurements']['chestMaxStops'].length - 1 ]) {
        this.alertMessage = chestMeasurement + ' is outside the specified range.';
        this.topMeasurementInRange = false;
        this.topMeasurementLegendColor = OUR_RED;
        alert( this.alertMessage );
      } else {
        this.topMeasurementInRange = true;
        this.topMeasurementLegendColor = OUR_BLUE;
      }
    }
    this.showAlert = false;
    this.alertMessage = '';
    this.chestMeasured = true;
    this.chestMeasurement = parseFloat(chestMeasurement);
    // tslint:disable-next-line:no-string-literal
    this.schoolNGrades['topMeasurements']['chestMeasurementTuples'].some(this.sideLengthExistsInMeasurements);
    this.checkShowSizes();
  }
  
  // Side Length measurement
  sideLengthExistsInMeasurements = (element, index, array) => {
    const lengthNum = this.chestMeasurement || 0;
    if (lengthNum >= element[0] && lengthNum <= element[1]) {
      this.matchedChestMeasurementIndex = index;
      // tslint:disable-next-line:radix
      const locationIndex = parseInt(index);
      // tslint:disable-next-line:no-string-literal
      this.suggestedTopLengths = [this.schoolNGrades['topMeasurements']['topLengths'][locationIndex]];
      this.sideLengthTop = this.suggestedTopLengths[0];
      this.sideLengthMeasurementTaken = true;
      return true;
    }
    return false;
  }

    //Waist measurement and bottom length calculation & selection
  /**
   *
   * @param waistMeasurement;
   */
   saveWaistMeasurementData = (waistMeasurement, eventName) => {
    if ( waistMeasurement && waistMeasurement.length <= 0 ) {
      return;
    }
    if ( eventName && eventName === 'focusout' ){
      if (waistMeasurement < this.schoolNGrades['bottomMeasurements']['waistMinStops'][0] ||
      waistMeasurement >
          this.schoolNGrades['bottomMeasurements']['waistRelaxStops'][ this.schoolNGrades['bottomMeasurements']['waistRelaxStops'].length - 1 ]) {
        this.alertMessage = waistMeasurement + ' is outside the specified range.';
        this.bottomMeasurementInRange = false;
        this.bottomMeasurementLegendColor = OUR_RED;
        alert( this.alertMessage );
      } else {
        this.bottomMeasurementInRange = true;
        this.bottomMeasurementLegendColor = OUR_BLUE;
      }
            
    }

    this.showAlert = false;
    this.alertMessage = '';
    this.waistMeasured = true;

    this.waistMeasurement = parseFloat(waistMeasurement);
    this.bottomLengthSelected(this.suggestedBottomLengths[0]); // For Chaitanya, no selection of bottom length from array - Sundar 30JAN2020
    this.schoolNGrades['bottomMeasurements']['waistMeasurementTuples'].some(this.bottomLengthExistsInMeasurements.bind(this));
    this.checkShowSizes();
  }
  /**
   *
   * @param element
   * @param index
   * @param array
   */
  bottomLengthExistsInMeasurements(element, index, array) {
    let lengthNum = this.waistMeasurement || 0;
    if (lengthNum >= element[0] && lengthNum <= element[1]) {
      this.matchedWaistMeasurementIndex = index;
      this.suggestedBottomLengths = this.schoolNGrades['bottomMeasurements']['sideLengths'][parseInt(index)]

      return true;
    }
    return false;
  }
  bottomLengthSelected(waistLength) {
    // this.sizeObject[productId] = variantId;
    this.sideLengthMeasurementTaken = true;
    this.showAlert = false;
    this.alertMessage = '';
    this.sideLengthDisplay = waistLength;
    this.sideLengthBottom = parseFloat(waistLength);
  }

  schoolSelection = (schoolName) => {

    this.schoolSelected = true;
    this.checkShowMeasurementPanel();
  }
  // Gender Selection
  genderSelection = (genderOption) => {
    this.genderSelected = true;
    this.showSizes = false;
    this.genderSelectedValue = genderOption;
    this.checkShowMeasurementPanel();
    if (genderOption === 'boys') {
      this.groupOptions = this.boysGroupOptions;
      this.groupsGradeMap = this.boysGroupsGradeMap;
    } else if (genderOption === 'girls') {
      this.groupOptions = this.girlGroupOptions;
      this.groupsGradeMap = this.girlsGroupsGradeMap;
    }
  }
  // Grade / Group Selection
  groupSelection = (groupOptionValue) => {
    if (groupOptionValue) {
      const groupOption = JSON.parse(groupOptionValue);

      this.groupSelected = true;
      this.showSizes = false;
      this.groupSelectedValue = groupOption.key;
      this.groupSelectedGradeValue = groupOption.value;
      this.checkShowMeasurementPanel();
      this.setSchoolGenderGroup();
    }
  }
  // Map Gender and Group
  setSchoolGenderGroup = () => {
    if (this.genderSelected && this.groupSelected && this.genderSelectedValue.length > 0 && this.groupSelectedValue.length > 0) {
      if (this.selectedSchool === BASE_SCHOOL) {
        this.schoolNGrades = this.shamrock[this.genderSelectedValue][this.groupSelectedValue];
        // tslint:disable-next-line:no-string-literal
        this.topMeasurementImage = this.shamrock[this.genderSelectedValue][this.groupSelectedValue]['images']['topMeasurementsImg'];
        // tslint:disable-next-line:no-string-literal
        this.bottomMeasurementImage = this.shamrock[this.genderSelectedValue][this.groupSelectedValue]['images']['bottomMeasurementsImg'];
        // tslint:disable-next-line:no-string-literal
        this.topMeasurementLegend = this.shamrock[this.genderSelectedValue][this.groupSelectedValue]['legend']['topMeasurementsLegend'];
        // tslint:disable-next-line:no-string-literal
        this.bottomMeasurementLegend = this.shamrock[this.genderSelectedValue][this.groupSelectedValue]['legend']['bottomMeasurementsLegend'];
        // tslint:disable-next-line:object-literal-key-quotes
        this.sizeMeasurementHelpModal({ 'temp': 'value' });
      }
    }
  }
  checkShowMeasurementPanel = () => {
    this.showMeasurementPanel = this.schoolSelected && this.genderSelected && this.groupSelected;
  }
  checkShowSizes = () => {
    this.alertMessage = '';
    this.showSizes = this.chestMeasured;
    console.log('Show Sizes ', this.showSizes, ' Saved measurements is ', this.savedMeasurements,
      ' and side length measurement taken ', this.sideLengthMeasurementTaken);
  }
  /**
   * End section - Size measurement functions
   */

  /**
   * Submit Form for TGS
   */

  goBackHome = (event) => {
    console.log('Running SaveMeasurements');
    if (!this.chestMeasured) {
      this.alertMessage = 'You must specify "' + this.topMeasurementLegend + '".';
      this.showAlert = true;
      alert(this.alertMessage);
      console.log('No chest measurement - returning');
      return;
    } else if (!this.waistMeasured) {
      this.alertMessage = 'You must specify "' + this.bottomMeasurementLegend + '".';
      this.showAlert = true;
      alert(this.alertMessage);
      return;
    } else if (!this.sideLengthMeasurementTaken) {
      this.alertMessage = 'You must Select Length for "Main - Bottom" from the drop-down values';
      this.showAlert = true;
      console.log('No side length measurement - returning');
      return;
    } else if (!this.topMeasurementInRange) {
      this.alertMessage = this.chestMeasurement + ' is outside the specified range.';
      this.showAlert = true;
      alert(this.alertMessage);
      console.log('No top measurement - returning');
      return;
    } else if (!this.bottomMeasurementInRange) {
      this.alertMessage = this.waistMeasurement + ' is outside the specified range.';
      this.showAlert = true;
      alert(this.alertMessage);
      return;
    }
    console.log('Checked for alerts');
    this.alertMessage = '';
    this.showAlert = false;

    interface IProductSizes { name: any; sizes: any; }
    // tslint:disable-next-line:prefer-const
    // tslint:disable-next-line:no-angle-bracket-type-assertion
    const bottomMeasurementsOtherProducts: any = this.schoolNGrades['bottomMeasurements']['otherProducts'].map(item => <IProductSizes>{
      name: item.name,
      sizes: item.sizes
    });

    // tslint:disable-next-line:no-string-literal
    // tslint:disable-next-line:no-angle-bracket-type-assertion
    // tslint:disable-next-line:no-string-literal
    // tslint:disable-next-line:no-angle-bracket-type-assertion
    // tslint:disable-next-line:no-string-literal
    const topMeasurementsOtherProducts: any = this.schoolNGrades['topMeasurements']['otherProducts']
      // tslint:disable-next-line:no-angle-bracket-type-assertion
      .map(element => <IProductSizes> {
        name: element.name,
        sizes: element.sizes
      });

      
    // tslint:disable-next-line:no-string-literal
    const mainBottomSizesArray = this.schoolNGrades['bottomMeasurements']['mainBottom']['sizes'][this.matchedWaistMeasurementIndex];
    // const mainBottomMeasurement = mainBottomSizesArray.length > 1 ? '28LXXX' : mainBottomSizesArray[0];
    this.finalSizeMaps = [
      // tslint:disable-next-line:no-string-literal
      {
        name: [this.schoolNGrades['topMeasurements']['mainTop']['name']],
        // tslint:disable-next-line:no-string-literal
        size: this.schoolNGrades['topMeasurements']['mainTop']['sizes'][this.matchedChestMeasurementIndex]
      },
      //  {name: [this.schoolNGrades['bottomMeasurements']['mainBottom']['name']], size: 'Size ' + mainBottomMeasurement }
      // tslint:disable-next-line:no-string-literal
      {
        name: [this.schoolNGrades['bottomMeasurements']['mainBottom']['name']],
        // tslint:disable-next-line:no-string-literal
        size: this.schoolNGrades['bottomMeasurements']['mainBottom']['sizes'][this.matchedWaistMeasurementIndex]
      }
      // {name: [this.schoolNGrades['accessories'][0]['name']],  size: 'Size ' + this.schoolNGrades['accessories'][0]['sizes'][0] }
    ];
    bottomMeasurementsOtherProducts.forEach((element, index) => {

      element.sizes.some((item, eIndex) => {
        if (eIndex === this.matchedWaistMeasurementIndex) {
          this.finalSizeMaps.push({ name: [element.name], size: element.sizes[eIndex] })
        }
      });
    });
    topMeasurementsOtherProducts.forEach((element, index) => {

      element.sizes.some((item, eIndex) => {
        if (eIndex === this.matchedChestMeasurementIndex) {
          this.finalSizeMaps.push({ name: [element.name], size: element.sizes[eIndex] })
        }
      });
    });
    // tslint:disable-next-line:no-string-literal
    this.schoolNGrades['accessories'].forEach((acc, indx) => {
      let thisAccessory = {};
      if (acc.grade === this.groupSelectedGradeValue) {
        thisAccessory = {
          name: acc.name,
          size: 'Size ' + acc.sizes[0]
        };
        this.finalSizeMaps.push(thisAccessory)
      }
    });

    this.savedMeasurements = true;
    this.editing = false;
    this.viewingResults = true;

    // tslint:disable-next-line:no-string-literal
    console.log([this.schoolNGrades['topMeasurements']['mainTop']['name']]);
    this.finalSizeMaps = [
      // tslint:disable-next-line:no-string-literal
      {
        // tslint:disable-next-line:no-string-literal
        name: [this.schoolNGrades['topMeasurements']['mainTop']['name']],
        // tslint:disable-next-line:no-string-literal
        size: this.schoolNGrades['topMeasurements']['mainTop']['sizes'][this.matchedChestMeasurementIndex]
      },
      //  {name: [this.schoolNGrades['bottomMeasurements']['mainBottom']['name']], size: 'Size ' + mainBottomMeasurement }
      // tslint:disable-next-line:no-string-literal
      {
        name: [this.schoolNGrades['bottomMeasurements']['mainBottom']['name']],
        // tslint:disable-next-line:no-string-literal
        size: this.schoolNGrades['bottomMeasurements']['mainBottom']['sizes'][this.matchedWaistMeasurementIndex]
      }
      // {name: [this.schoolNGrades['accessories'][0]['name']],  size: 'Size ' + this.schoolNGrades['accessories'][0]['sizes'][0] }

    ];
    bottomMeasurementsOtherProducts.forEach((element, index) => {

      element.sizes.some((item, eIndex) => {
        if (eIndex === this.matchedWaistMeasurementIndex) {
          this.finalSizeMaps.push({ name: [element.name], size: element.sizes[eIndex] })
        }
      });
    });
    topMeasurementsOtherProducts.forEach((element, index) => {

      element.sizes.some((item, eIndex) => {
        if (eIndex === this.matchedChestMeasurementIndex) {
          this.finalSizeMaps.push({ name: [element.name], size: element.sizes[eIndex] })
        }
      });
    });
    // tslint:disable-next-line:no-string-literal
    this.schoolNGrades['accessories'].forEach((acc, indx) => {
      let thisAccessory = {};
      if (acc.grade === this.groupSelectedGradeValue) {
        thisAccessory = {
          name: acc.name,
          size: 'Size ' + acc.sizes[0]
        };
        this.finalSizeMaps.push(thisAccessory)
      }
    });

    this.savedMeasurements = true;
    console.log(this.savedMeasurements);
    this.editing = false;
    this.viewingResults = true;
    console.log('Calling Check Show Sizes from saveMeasurements');
    this.checkShowSizes();

    const headers = { 'content-type': 'application/json'}  
    const body=JSON.stringify(this.finalSizeMaps);
    this.finalPayload.response = this.finalSizeMaps

    console.log(body)
    // return this.http.post("https://flow.platform.dataiken.com" + 'tgs-back-home', body,{'headers':headers})
    this.posting = true;

  }
  onReset(event): void {
    this.posting = false;
  }

  ngAfterViewChecked() {
    if (this.posting && this.myFormPost) {
      this.myFormPost.nativeElement.submit(this.finalSizeMaps);
    }
  }

}
